import Vue from 'vue';
import Vuex from 'vuex';
import cookieStore from './cookieStore.js';
import wizardStore from './wizardStore.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    cookies: cookieStore,
    wizard: wizardStore,
  },
});
