const store = {
  namespaced: true,
  state: {
    isConsentCookieSet: false,
    isConsentGiven: false,
  },
  mutations: {
    updateConsentGiven(state, payload) {
      state.isConsentCookieSet = true;
      state.isConsentGiven = payload;
    },
  },
};

export default store;
