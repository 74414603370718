function importAll(r) {
  let icons = [];
  r.keys().map((item) => { icons[item.replace('./', '')] = r(item); });
  return icons;
}

const svgIcons = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));
const allIcons = Object.keys(svgIcons).map((icon) => icon.replace('.svg', ''));

export default allIcons;
