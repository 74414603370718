import { en, nl, pl } from '@braid/vue-formulate-i18n';
import { validateBSN } from 'elfproef';
import isHtml from 'is-html';

export default {
  plugins: [nl, en, pl],
  locale: 'nl',
  rules: {
    unique: ({ value }, ...args) => {
      let unique = true;
      args.forEach((arg) => {
        const flatArr = [];
        value.forEach((val) => {
          if (typeof val[arg] !== 'undefined') {
            if (flatArr.length > 0) {
              if (flatArr.indexOf(val[arg]) === -1) {
                flatArr.push(val[arg]);
              } else {
                unique = false;
              }
            } else {
              flatArr.push(val[arg]);
            }
          }
        });
      });
      return unique;
    },
    nohtml: ({ value }) => {
      return !isHtml(value);
    },
    phonenumber: ({ value }) => {
      const regex = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
      return regex.test(value);
    },
    bsn: ({ value }) => {
      return validateBSN(value);
    },
    zipcode: ({ value }) => {
      const regex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      return regex.test(value);
    }
  },
}
