import Vue from 'vue';
import VueGtm from 'vue-gtm';

const keys = {
  partou: process.env.VUE_APP_GTM_API_KEY_PARTOU,
  mijnpartou: process.env.VUE_APP_GTM_API_KEY_MIJNPARTOU,
  partougroup: process.env.VUE_APP_GTM_API_KEY_PARTOUGROUP,
};

let hostname;
let domain;

if (typeof window !== 'undefined') {
  hostname = window.location.hostname;
  domain = hostname.split('.').slice(-2)[0]; // Extract the domain
}

if (!keys[domain]) {
  console.error('Unknown domain');
  domain = 'partou';
}
Vue.use(VueGtm, {
  id: keys[domain],
  defer: true,
  compatibility: true,
  enabled: true,
  debug: process.env.NODE_ENV === 'development',
  loadScript: true,
});

export default VueGtm;
