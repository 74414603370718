const DEFAULT_SELECTED_CHILDREN = {
  children: [],
};

const store = {
  namespaced: true,
  state: {
    personalDetails: JSON.parse(window.sessionStorage.getItem('wizardStore.personalDetails')) || {},
    selectedChildren:
      JSON.parse(window.sessionStorage.getItem('wizardStore.selectedChildren')) ||
      DEFAULT_SELECTED_CHILDREN,
  },
  mutations: {
    updatePersonalDetails(state, payload) {
      state.personalDetails = { ...state.personalDetails, ...payload };
    },
    updateSelectedChildren(state, payload) {
      state.selectedChildren = { ...state.selectedChildren, ...payload };
    },
  },
  actions: {
    setPersonalDetails({ state, commit }, payload) {
      commit('updatePersonalDetails', payload);
      window.sessionStorage.setItem(
        'wizardStore.personalDetails',
        JSON.stringify(state.personalDetails),
      );
    },
    setSelectedChildren({ state, commit }, payload) {
      commit('updateSelectedChildren', payload);
      window.sessionStorage.setItem(
        'wizardStore.selectedChildren',
        JSON.stringify(state.selectedChildren),
      );
    },
  },
};

export default store;
